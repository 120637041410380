import heic2any from "heic2any";

export const isHeicImageFile = function (file) {
  return (
    file.type === "image/heic" || file.name.toLowerCase().includes(".heic")
  );
};
export const convertHeicToJpeg = async function (file) {
  return await heic2any({
    blob: file,
    toType: "image/jpeg",
    quality: 0.5, // cuts the quality and size by half
  });
};
