export const checkoutText = {
  header: `It looks like a medication abortion may be right for you.`,
  body: `A licensed and trained pharmacist will review your answers and be in touch with you via text message within 24 hours. If medically appropriate, treatment may be prescribed to you. Your personal information is securely transmitted via HIPAA compliant transfer to the pharmacist.`,
  // body: `Your doctor consult will be provided by Beluga Health, a telemedicine provider that serves patients across the country. Your $30 payment goes directly to Beluga Health and covers the cost of your doctor consult. If medically appropriate, treatment may be prescribed to you. The doctor will contact you within the next 24 hours (often times much sooner) via email and text message to complete your consultation.`,
  footer: `In order to verify your identity, please upload a picture of your government-issued photo ID.`,
};

export const introPage = {
  header: `This project aims to improve access to medication abortion using pharmacist providers to prescribe directly to any person who is pregnant and fits the requirements for medication abortion in Washington State.  Medications will only be mailed to Washington State addresses.`,
  body: `In order to fill out this form, you will need the following:<br/>
    <br/>
    1) Date of your last period,<br/>

    2) Date of the positive pregnancy test, and<br/>

    3) Photo ID (current ID with full name and date of birth).<br/>
    <br/>
    *If you do not know the date of your last period, that's okay--still fill out the form.
    If you have not taken a pregnancy test, please complete one prior to filling out this form.`,
  footer: `Beluga Health is a US licensed telemedicine provider.`,
};

export const paymentSuccess = {
  header: `Thank you! Your information was submitted successfully.`,
  body: `A pharmacist will now review your intake form. The pharmacist will contact you within the next 24 hours (often times much sooner) via text message to complete your consultation.`,
  footer: `Beluga Health is a US licensed telemedicine provider.`,
};

export const dataSecure = {
  header:
    "Your personal information is securely transmitted via HIPAA compliant transfer to the pharmacist.",
};
