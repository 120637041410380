import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

import { apiKey } from "../config";

const verifyPhone = async (phone, setPhoneError, setIsPhoneValidated) => {
  try {
    const phoneResponse = await API.post("brains", "/external/phoneLookup", {
      credentials: "include",
      body: {
        phone,
        apiKey,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (phoneResponse.status !== 200) {
      setPhoneError("Please enter your cell phone number");
      setIsPhoneValidated(false);
    } else {
      setPhoneError("");
      setIsPhoneValidated(true);
    }
  } catch (err) {
    setPhoneError("Please enter your cell phone number");
    setIsPhoneValidated(false);
  }
};

const createVisit = async (
  cardInfo,
  formData,
  demographicData,
  setError,
  stripeOrPaypal,
  turnstileToken,
) => {
  let stringifiedAnswers = formData;
  stringifiedAnswers = Object.keys(stringifiedAnswers).forEach((k) => {
    if (Array.isArray(stringifiedAnswers[k])) {
      stringifiedAnswers[k] = stringifiedAnswers[k].join(";");
    }
  });
  const { allergies, medicalConditions, selfReportedMeds, sex, ...formRest } =
    formData;
  const visitId = uuidv4();
  const body = {
    formObj: {
      ...demographicData,
      selfReportedMeds: selfReportedMeds || "None",
      allergies: allergies || "None",
      medicalConditions: medicalConditions || "None",
      sex: sex || "Other",
      ...formRest,
    },
    cardInfo,
    company: "uplift",
    visitType: "abortion",
    apiKey,
    visitId,
    stripeOrPaypal,
  };

  try {
    const visitCreationResponse = await API.post("brains", "/form/uplift", {
      credentials: "include",
      body,
      headers: {
        "Content-Type": "application/json",
        "X-Turnstile-Token": turnstileToken,
      },
    });
    if (visitCreationResponse.status !== 200) {
      if (visitCreationResponse.error === "State not valid") {
        setError(
          "Unfortunately we're not able to provide service in your state at this time. Please check back at a later date.",
        );
      } else {
        setError(
          "Something went wrong, please check all your info and try again",
        );
      }
      return;
    }
    return visitCreationResponse.data.visitId;
  } catch (err) {
    setError("Something went wrong, please check all your info and try again.");
    return;
  }
};

const submitPhoto = async (currentVisitId, localState, setError, navigate) => {
  const body = {
    visitId: currentVisitId,
    image: {
      mime: "image/jpeg",
      data: localState.photo,
    },
    apiKey,
  };
  try {
    const photoResponse = await API.post("brains", "/external/receivePhoto", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    if (photoResponse.status !== 200) {
      setError("Something went wrong, please upload a photo and try again.");
      return;
    }
    navigate("/success");
  } catch (err) {
    setError("Something went wrong, please upload a photo and try again.");
    return;
  }
};

export const routerMap = {
  verifyPhone,
  createVisit,
  submitPhoto,
};
