import { Turnstile } from "@marsidev/react-turnstile";
import React from "react";

export default function TurnstileWidget({ onTokenChange }) {
  const [token, setToken] = React.useState();

  React.useEffect(() => {
    if (token) {
      onTokenChange(token);
    }
  }, [token, onTokenChange]);

  return (
    <Turnstile
      siteKey="0x4AAAAAAAByviylsx6fu1CH"
      onSuccess={(token) => setToken(token)}
    />
  );
}
