import React, { useState, useEffect } from "react";

import { introPage } from "../DataFiles/standardText";

import "../MainForm/MainForm.css";

const IntroPage = (props) => {
  const { goToForm, formData, setFormData } = props;

  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [allConsents, setAllConsents] = useState({});

  const setChecked = (e) => {
    if (allConsents[e.target.name]) {
      const { [e.target.name]: trash, ...rest } = allConsents;
      setAllConsents(rest);
    } else {
      setAllConsents({
        ...allConsents,
        [e.target.name]: true,
      });
    }
  };

  useEffect(() => {
    if (
      allConsents.person &&
      allConsents.resident &&
      allConsents.age &&
      allConsents.info &&
      allConsents.tc &&
      allConsents.privacy &&
      allConsents.abortionPills
    ) {
      setIsConsentChecked(true);
    } else {
      setIsConsentChecked(false);
    }
  }, [allConsents]);

  useEffect(() => {
    setFormData({
      ...formData,
      consentsSigned: isConsentChecked,
    });
  }, [isConsentChecked]);

  return (
    <div className="pageContainer">
      <div className="pageHeader">
        <div className="pageTitle"></div>
      </div>
      <div className="introContainer">
        <div className="messageHeader hiThere">
          Welcome to the Pharmacist Abortion Access Project
        </div>
        <div className="introSubheader">
          <div>{introPage.header}</div>
          <div
            className="introFooter"
            dangerouslySetInnerHTML={{ __html: introPage.body }}
          />
        </div>
        <div className="introConsents">
          <div>
            <input
              type="checkbox"
              name="person"
              onClick={setChecked}
              className="checkBox"
            />
            <span>
              I certify that I am the person who is pregnant and wants
              medication abortion for termination of the pregnancy.
            </span>
          </div>
          <div>
            <input
              type="checkbox"
              name="resident"
              onClick={setChecked}
              className="checkBox"
            />
            <span>I am a resident of Washington State</span>
          </div>
          <div>
            <input
              type="checkbox"
              name="age"
              onClick={setChecked}
              className="checkBox"
            />
            <span>I am 18 years of age or older</span>
          </div>
          <div>
            <input
              type="checkbox"
              name="tc"
              onClick={setChecked}
              className="checkBox"
            />
            <span>
              I agree to the{" "}
              <a
                href="https://customerconsents.s3.amazonaws.com/abortionTermsAndConditions.pdf"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                Terms & Conditions
              </a>
            </span>
          </div>
          <div>
            <input
              type="checkbox"
              name="privacy"
              onClick={setChecked}
              className="checkBox"
            />
            <span>
              I agree to the{" "}
              <a
                href="https://customerconsents.s3.amazonaws.com/abortionPrivacyPolicy.pdf"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                Privacy Policy
              </a>
            </span>
          </div>
          <div>
            <input
              type="checkbox"
              name="info"
              onClick={setChecked}
              className="checkBox"
            />
            <span>
              I acknowledge that I have read and understand the{" "}
              <a
                href="https://customerconsents.s3.amazonaws.com/abortionInfoDoc.pdf"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                information form about abortion with pills
              </a>
            </span>
          </div>
          <div>
            <input
              type="checkbox"
              name="abortionPills"
              onClick={setChecked}
              className="checkBox"
            />
            <span>
              I agree to the{" "}
              <a
                href="https://customerconsents.s3.amazonaws.com/abortionconsent.pdf"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                Consent for an abortion with pills
              </a>
            </span>
          </div>
        </div>
        <button
          className="orderButton getStartedButton"
          onClick={goToForm}
          disabled={!isConsentChecked}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default IntroPage;
