import React, { useState, useEffect } from "react";

import "./LoadingModal.css";

const LoadingModal = (props) => {
  let modalCss = "loadingModal";
  if (!props.show) {
    modalCss += " hide";
  }
  return (
    <div className={modalCss}>
      <div className="loader"></div>
    </div>
  );
};

export default LoadingModal;
