export const heightRange = [
  `3'0"`,
  `3'1"`,
  `3'2"`,
  `3'3"`,
  `3'4"`,
  `3'5"`,
  `3'6"`,
  `3'7"`,
  `3'8"`,
  `3'9"`,
  `3'10"`,
  `3'11"`,
  `4'0"`,
  `4'1"`,
  `4'2"`,
  `4'3"`,
  `4'4"`,
  `4'5"`,
  `4'6"`,
  `4'7"`,
  `4'8"`,
  `4'9"`,
  `4'10"`,
  `4'11"`,
  `5'0"`,
  `5'1"`,
  `5'2"`,
  `5'3"`,
  `5'4"`,
  `5'5"`,
  `5'6"`,
  `5'7"`,
  `5'8"`,
  `5'9"`,
  `5'10"`,
  `5'11"`,
  `6'0"`,
  `6'1"`,
  `6'2"`,
  `6'3"`,
  `6'4"`,
  `6'5"`,
  `6'6"`,
  `6'7"`,
  `6'8"`,
  `6'9"`,
  `6'10"`,
  `6'11"`,
  `7'0"`,
  `7'1"`,
  `7'2"`,
  `7'3"`,
  `7'4"`,
  `7'5"`,
  `7'6"`,
  `7'7"`,
  `7'8"`,
  `7'9"`,
  `7'10"`,
  `7'11"`,
  `8'0"`,
  `8'1"`,
  `8'2"`,
  `8'3"`,
  `8'4"`,
  `8'5"`,
  `8'6"`,
  `8'7"`,
  `8'8"`,
  `8'9"`,
  `8'10"`,
  `8'11"`,
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const dates = () => {
  const datesArray = [];
  for (let i = 1; i < 32; i++) {
    datesArray.push(i);
  }
  return datesArray;
};

export const years = (startYear) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  startYear = startYear || 1930;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

const hardStopText = `Our team of pharmacists aims to provide safe and efficient online medication abortion services.
You have answered one or more questions that we think need further investigation by a clinician before prescribing abortion pills. 
Please contact a clinic at one of the following online resources to access further clinical care:<br/>
Plan C - <a href="https://www.plancpills.org/" target=_blank>https://www.plancpills.org</a><br/>
Abortion Finder - <a href="https://www.abortionfinder.org" target=_blank>https://www.abortionfinder.org</a><br/>
Planned Parenthood - <a href="https://www.plannedparenthood.org/abortion-access" target=_blank>https://www.plannedparenthood.org/abortion-access</a>`;

export const questionnaire = [
  // 1
  {
    question:
      "Please enter the date of the first day you started spotting or bleeding and had a normal period",
    answers: [
      {
        answer: "date",
        showConditional: false,
      },
      {
        answer:
          "I do not know the date I started spotting or bleeding and had a normal period",
        showConditional: true,
      },
    ],
    type: "date",
    isConditional: false,
  },
  // 2
  {
    question:
      "Please enter the week that you started spotting or bleeding and had a normal period",
    answers: [
      {
        answer: "week",
        showConditional: false,
      },
      {
        answer:
          "I do not know the week I started spotting or bleeding and had a normal period",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
    ],
    type: "date",
    isConditional: true,
  },
  // 3
  {
    question: "Are your periods Regular or Irregular?",
    answers: [
      {
        answer:
          "My periods are regular (regular periods come every month with roughly the same amount of days in between.)",
        showConditional: false,
      },
      {
        answer: "They never come around the same time each month",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
      {
        answer: "I consistently go more than 5 weeks without a period",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
      {
        answer: "I sometimes skip one month or more without a period",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
    ],
    type: "radio",
    isConditional: false,
  },
  // 4
  {
    question:
      "Do you know the date of your first positive home pregnancy test?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  // 5
  {
    question:
      "Please enter the date of your first positive home pregnancy test:",
    answers: ["date"],
    type: "date",
    isConditional: true,
  },
  // 6
  {
    question: "Have you had an ultrasound done in this pregnancy?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  // 7
  {
    question: "Metaquestion",
    isConditional: true,
    subQuestions: [
      {
        question: "What was the date of the ultrasound?",
        answers: ["date"],
        type: "date",
        isConditional: false,
      },
      {
        question:
          "How far along were you in the pregnancy on the day your ultrasound was performed?",
        subText: `Please write your answer in week/day format, for example "7 weeks 3 days".`,
        answers: ["freeText"],
        type: "freeText",
        isConditional: false,
      },
    ],
  },
  // 8
  {
    question: "Who will be taking the abortion medications?",
    answers: [
      {
        answer: "I will be taking the abortion medications",
        showConditional: false,
      },
      {
        answer:
          "A friend, family member, or partner will take the abortion medications",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
      {
        answer: "Other",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
    ],
    type: "radio",
    isConditional: false,
  },
  // 9
  {
    question: "Have you recently needed to take medication for anemia?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  // 12
  {
    question:
      "If you have been pregnant before, have you ever had a hemorrhage (excessive bleeding) after delivery/miscarriage/abortion that required a blood transfusion?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
      {
        answer: "No",
        showConditional: false,
      },
      {
        answer: "I've never been pregnant",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  // 13
  {
    question: `Please check any of the following medical conditions or situations that apply to you.`,
    subText: `If none apply, please check "None of these apply".`,
    answers: [
      {
        answer: "Allergy to prostaglandins or mifepristone or misoprostol",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
      {
        answer: "Chronic renal (kidney) failure",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
      {
        answer: "Chronic adrenal insufficiency",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },

      {
        answer:
          "Long-term use of systemic corticosteroids (for example, you take a pill daily or get regular steroid injections for a medical condition)",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
      {
        answer: "Hemorrhagic (bleeding) disorder",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
      {
        answer: "Inherited porphyria",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
      {
        answer: "Currently using anticoagulant therapy",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  // 14
  {
    question: `Please check all of the following that apply to you.`,
    subText: `If none apply please check "None of these apply".`,
    answers: [
      {
        answer: "I have had bleeding or spotting within the past week",
        showConditional: false,
      },
      {
        answer:
          "History of tubal (fallopian tube) surgery, including tubal ligation or permanent birth control",
        showConditional: false,
      },
      {
        answer: "History of treatment for Pelvic Inflammatory Disease (PID)",
        showConditional: false,
      },

      {
        answer: "History of Endometriosis",
        showConditional: false,
      },
      {
        answer: "History of ectopic pregnancy",
        showConditional: false,
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  {
    question: `Please check all of the following that apply to you.`,
    subText: `If none apply please check "None of these apply".`,
    answers: [
      {
        answer:
          "I have had pain or tenderness in pelvic area on one side or significant pain on both sides within the past week",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
      {
        answer: "IUD in place now or when you became pregnant",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  // 15
  {
    superText: `The following two questions will assess your safety and preparedness for having an abortion at home.`,
    question: `Please choose the best answer:`,
    answers: [
      {
        answer: "I am making this decision by myself",
        showConditional: false,
      },
      {
        answer: "I am not making this decision by myself",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
    ],
    type: "radio",
    isConditional: false,
  },
  // 16
  {
    question: "I feel that I am being pressured to get an abortion",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
        error: {
          header: hardStopText,
          body: "",
        },
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  // 17
  {
    question:
      "Is there anything else in your pregnancy, abortion, or medical history that we should be aware of?",
    subText:
      "(previous problems with medication abortion, recent failed medication abortion, or any other health history)",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  // 18
  {
    question:
      "Please enter anything about your pregnancy, abortion, or medical history",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  // 19
  {
    question:
      "Please list any current medicines, vitamins or dietary supplements you take regularly.",
    subText: "Please include the dosage.",
    answers: ["selfReportedMeds"],
    type: "freeText",
    standardDemo: "selfReportedMeds",
    isConditional: false,
  },
  // 20
  {
    question: "Please list all known allergies:",
    answers: ["allergies"],
    type: "freeText",
    standardDemo: "allergies",
    isConditional: false,
  },
  // 21
  {
    question:
      "Please list any prior or current medical conditions or serious problems:",
    answers: ["medicalConditions"],
    type: "freeText",
    standardDemo: "medicalConditions",
    isConditional: false,
  },
];
